// Fee Name api Routes
export const feeNameList = '/configuration/fee-name/list'
export const feeNameStore = '/configuration/fee-name/store'
export const feeNameUpdate = '/configuration/fee-name/update'
export const feeToggleStatus = '/configuration/fee-name/toggle-status'
// Service Name api Routes
export const serviceNameList = '/configuration/service-name/list'
export const serviceNameStore = '/configuration/service-name/store'
export const serviceNameUpdate = '/configuration/service-name/update'
export const serviceToggleStatus = '/configuration/service-name/toggle-status'

// Market Name api Routes
export const marketNameList = '/configuration/market-name/list'
export const marketNameStore = '/configuration/market-name/store'
export const marketNameUpdate = '/configuration/market-name/update'
export const marketToggleStatus = '/configuration/market-name/toggle-status'
// Step Name api Routes
export const stepNameList = '/configuration/step-name/list'
export const stepNameStore = '/configuration/step-name/store'
export const stepNameUpdate = '/configuration/step-name/update'
export const stepToggleStatus = '/configuration/step-name/toggle-status'
// ekpay namage api Routes
export const ekpayList = '/configuration/ekpay-manage/list'
export const ekpayStore = '/configuration/ekpay-manage/store'
export const ekpayUpdate = '/configuration/ekpay-manage/update'
export const ekpayToggleStatus = '/configuration/ekpay-manage/toggle-status'

// ekpay namage api Routes
export const challanList = '/configuration/ekpay-challan/list'
export const challanStore = '/configuration/ekpay-challan/store'
export const challanUpdate = '/configuration/ekpay-challan/update'
export const challanToggleStatus = '/configuration/ekpay-challan/toggle-status'

// Step process api Routes
export const stepProcessList = '/configuration/step-process/list'
export const stepProcessStore = '/configuration/step-process/store'
export const stepProcessUpdate = '/configuration/step-process/update'

// Step process api Routes
export const fieldAssignList = '/configuration/field-assign/list'
export const fieldAssignStore = '/configuration/field-assign/store'
export const fieldAssignUpdate = '/configuration/field-assign/update'
export const fieldAssignRemove = '/configuration/field-assign/remove'

// Step process api Routes
export const reportProcessList = '/configuration/report-process/list'
export const reportProcessStore = '/configuration/report-process/store'
export const reportProcessUpdate = '/configuration/report-process/update'

// Step Assign api Routes
export const stepAssignList = '/configuration/step-assign/list'
export const feeListget = '/configuration/step-assign/get-fees'
export const stepAssignStore = '/configuration/step-assign/store'
export const stepAssignUpdate = '/configuration/step-assign/update'

// Step Fee Assign API Routes
export const stepFeeAssignList = '/configuration/step-fee-assign/list'
export const stepFeeAssignGetFeesList = '/configuration/step-fee-assign/get-fees'
export const stepFeeAssignStore = '/configuration/step-fee-assign/store'
export const stepFeeAssignUpdate = '/configuration/step-fee-assign/update'

// Step Fee Assign API Routes
export const preconditionList = '/configuration/service-precondition/list'
export const preconditionStore = '/configuration/service-precondition/store'
export const preconditionUpdate = '/configuration/service-precondition/update'
export const toggleStatus = '/configuration/service-precondition/toggle-status'

// Step Form api Routes
export const stepFormList = '/configuration/step-form/list'
export const stepListget = '/configuration/step-form/get-steps'
export const stepFormStore = '/configuration/step-form/store'
export const stepFormUpdate = '/configuration/step-form/update'
export const dynamicFormEditData = '/configuration/step-form/edit'
export const stepFormView = '/configuration/step-form/view'

// Step Form View Routes
export const stepFormViewList = '/configuration/step-form-view/list'
export const stepFormViewStore = '/configuration/step-form-view/store'
export const stepFormViewEditData = '/configuration/step-form-view/edit'
export const stepFormViewUpdate = '/configuration/step-form-view/update'
export const stepFormViewDetail = '/configuration/step-form/view'
export const dynamicFormData = '/configuration/step-form/edit'

export const componentListDropdown = 'common/component-list'
// Form Data Store In DB
export const formDataStore = '/configuration/step-form/store-data'
// Dynamic form approve reject

// Form Assign api Routes
export const formAssignList = '/configuration/service-step-form-assign/list'
export const formAssignStore = '/configuration/service-step-form-assign/store'
export const formAssignUpdate = '/configuration/service-step-form-assign/update'

// Menu create to common service
export const menuList = 'master-menu/list'
export const menuStore = 'master-menu/store'
export const menuUpdate = 'master-menu/update'

// Menu Assign to license
export const menuAssignList = '/configuration/menu-assign/list'
export const menuAssignStore = '/configuration/menu-assign/store'
export const menuAssignUpdate = '/configuration/menu-assign/update'

// Document Type api Routes
export const documentTypeList = '/configuration/document-types/list'
export const documentTypeStore = '/configuration/document-types/store'
export const documentTypeUpdate = '/configuration/document-types/update'
export const documentTypeToggleStatus = '/configuration/document-types/toggle-status'

// Auto generate number type api Routes
export const autoGenNumTypeList = '/configuration/auto-gen-num-types/list'
export const autoGenNumTypeStore = '/configuration/auto-gen-num-types/store'
export const autoGenNumTypeUpdate = '/configuration/auto-gen-num-types/update'
export const autoGenNumTypeToggleStatus = '/configuration/auto-gen-num-types/toggle-status'

// document builder routes
const document = '/configuration/lr-documents/'
export const documentList = document + 'list'
export const documentStore = document + 'store'
export const documentUpdate = document + 'update'
export const documentShow = document + 'show'

// dashboard card routes
const card = '/configuration/dashboard-card/'
export const cardList = card + 'list'
export const cardStore = card + 'store'
export const cardUpdate = card + 'update'
export const cardToggle = card + 'toggle-status'

// dashboard card routes
export const dasbhoard = '/dashboard'

// Report Header Api
export const reportHeadingList = '/configuration/report-heading/list'
export const reportHeadingStore = '/configuration/report-heading/store'
export const reportHeadingUpdate = '/configuration/report-heading/update'
export const reportToggleStatus = '/configuration/report-heading/toggle-status'
export const reportHeaderDestroy = '/configuration/report-heading/destroy'

// Renew/Lost Fee api Routes
export const renewLostList = '/configuration/renew-lost-fee/list'
export const renewLostStore = '/configuration/renew-lost-fee/store'
export const renewLostUpdate = '/configuration/renew-lost-fee/update'
export const renewLostToggleStatus = '/configuration/renew-lost-fee/toggle-status'

// Report Menu Assign to license
export const reportMenuAssignList = '/configuration/report-menu-assign/list'
export const reportMenuAssignStore = '/configuration/report-menu-assign/store'
export const reportMenuAssignUpdate = '/configuration/report-menu-assign/update'

// get Org Wise Service
export const orgservice = '/configuration/service-name/orgservice'

// Lab api Routes
export const labList = '/configuration/lab/list'
export const labStore = '/configuration/lab/store'
export const labUpdate = '/configuration/lab/update'
export const labToggleStatus = '/configuration/lab/toggle-status'

// ingredient api Routes
export const ingredientList = '/configuration/active-ingredient/list'
export const ingredientStore = '/configuration/active-ingredient/store'
export const ingredientUpdate = '/configuration/active-ingredient/update'
export const ingredientToggleStatus = '/configuration/active-ingredient/toggle-status'

// agriculture product type api Routes
export const agricultureProductTypeList = '/configuration/agriculture-product-type/list'
export const agricultureProductTypeStore = '/configuration/agriculture-product-type/store'
export const agricultureProductTypeUpdate = '/configuration/agriculture-product-type/update'
export const agricultureProductTypeToggleStatus = '/configuration/agriculture-product-type/toggle-status'

// agriculture product type api Routes
export const fertilizerNameList = '/configuration/fertilizer-name/list'
export const fertilizerNameStore = '/configuration/fertilizer-name/store'
export const fertilizerNameUpdate = '/configuration/fertilizer-name/update'
export const fertilizerNameToggleStatus = '/configuration/fertilizer-name/toggle-status'

// Crop api Routes
export const cropNameList = '/configuration/crop-name/list'
export const cropNameStore = '/configuration/crop-name/store'
export const cropNameUpdate = '/configuration/crop-name/update'
export const cropNameToggleStatus = '/configuration/crop-name/toggle-status'

// agriculture product type api Routes
export const agricultureProductList = '/configuration/agriculture-product/list'
export const agricultureProductStore = '/configuration/agriculture-product/store'
export const agricultureProductUpdate = '/configuration/agriculture-product/update'
export const agricultureProductToggleStatus = '/configuration/agriculture-product/toggle-status'

// Org Notifications
export const orgNotesList = '/configuration/org-notes/list'
export const orgNotesStore = '/configuration/org-notes/store'
export const orgNotesUpdate = '/configuration/org-notes/update'
export const orgNotesToggleStatus = '/configuration/org-notes/toggle-status'

// BADC region list
export const regionList = '/configuration/master-region-info/list'
export const regionStore = '/configuration/master-region-info/store'
export const regionUpdate = '/configuration/master-region-info/update'
export const regionToggleStatus = '/configuration/master-region-info/toggle-status'
export const regionDestroy = '/configuration/master-region-info/destroy'

// Editable Data list
export const editableDataList = '/configuration/editable-data/list'
export const editableDataStore = '/configuration/editable-data/store'
export const editableDataUpdate = '/configuration/editable-data/update'
export const editableDataToggleStatus = '/configuration/editable-data/toggle-status'
export const editableDataDestroy = '/configuration/editable-data/destroy'

// Notice api Routes
export const noticeList = '/configuration/notice/list'
export const noticeStore = '/configuration/notice/store'
export const noticeUpdate = '/configuration/notice/update'
export const noticeToggleStatus = '/configuration/notice/toggle-status'

// Service Wise Office Type api Routes
export const serviceofficeTypeList = '/configuration/service-office-type/list'
export const serviceofficeTypeStore = '/configuration/service-office-type/store'
export const serviceofficeTypeUpdate = '/configuration/service-office-type/update'

// BADC Org region list
export const regionBadcList = '/configuration/master-region-modify/list'
export const regionBadcStore = '/configuration/master-region-modify/store'
export const regionBadcUpdate = '/configuration/master-region-modify/update'

// BADC Fertilizer region list
export const regionBadcFertilizerList = '/configuration/master-badc-fertilizer-region-modify/list'
export const regionBadcFertilizerStore = '/configuration/master-badc-fertilizer-region-modify/store'
export const regionBadcFertilizerUpdate = '/configuration/master-badc-fertilizer-region-modify/update'

// Company Attachment
export const daeCompanyAttachment = '/configuration/dae-company-attachment/list'
export const daeBinCompanyAttachment = '/configuration/dae-company-attachment/bin-search'
export const daeBinCompanyAttachmentList = '/configuration/dae-company-attachment/list'
export const daeBinCompanyAttachmentStore = '/configuration/dae-company-attachment/store'
export const daeBinCompanyAttachmentUpdate = '/configuration/dae-company-attachment/update'
