import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    })
  },
  methods: {
      userCheck () {
        if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
          return this.authUser.org_id
        } else {
          return 0
        }
      }
  }
}
